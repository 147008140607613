import React, { useState, useEffect } from "react";
import { Link } from "react-scroll";
import Video from './Video';
import Roadmap from './Roadmap';
import Piechart from './Piechart';
import Faq from './Faq';
import Community from './Community'; // Adjust the path according to your project structure
import "./Home.css";
import "./Roadmap.css";
import "./Video.css";
import "./Faq.css";
import "../Navbar.css"; // Create and import Navbar CSS for styling
import "../Piechart.css";
import "./Community.css";

const Home = () => {
  const [images, setImages] = useState([
    "./images/Group_nft_01.png",
    "/images/NFT_01_C2.png",
    "/images/r2.png",
    // Add more image paths as needed
  ]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    // Change image every 5 seconds
    const interval = setInterval(() => {
      setCurrentImageIndex(prevIndex =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [images]);

  return (
    <div >
    <br></br><br></br>
      <nav className="navbar">
        <img src="./images/r2.png" alt="Logo" className="navbar-logo" /> {/* Add your logo image here */}
        <ul className="navbar-list">
          <li className="navbar-item">
            <Link to="home" smooth={true} duration={500}>
              <button>Home</button>
            </Link>
          </li>
          <li className="navbar-item">
            <Link to="video" smooth={true} duration={500}>
              <button>Video</button>
            </Link>
          </li>
          <li className="navbar-item">
            <Link to="roadmap" smooth={true} duration={500}>
              <button>Roadmap</button>
            </Link>
          </li>
          <li className="navbar-item">
            <Link to="faq" smooth={true} duration={500}>
              <button>FAQ</button>
            </Link>
          </li>
          <li className="navbar-item">
            <Link to="community" smooth={true} duration={500}>
              <button>Community</button>
            </Link>
          </li>
        </ul>
      </nav>
      {/* Sections */}
      <div id="home" class="homecolor">
        <img src={images[currentImageIndex]} alt="NFT group" style={{ width: "90%", margin: "0 auto", display: "block" }} />
      </div>
      <div id="video" class="homecolor">
        <Video />
      </div>
      <div id="roadmap" class="homecolor">
        <Roadmap />
      </div>
      <div id="piechart" class="homecolor">
        <Piechart />
      </div>
      <div id="faq" class="homecolor">
        <Faq />
      </div>
    </div>
  );
}

export default Home;
