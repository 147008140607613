import React from "react";
import "../Piechart.css"; // Assuming you have a CSS file for styling

const Piechart = () => {
    return (
        <div className="piechart-container">
            <div className="responsive-center">
                <div className="image-container">
                    <div className="floating-text"></div>
                </div>
            </div>
           
        </div>

    );
};

export default Piechart;
