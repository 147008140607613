import React from 'react';
import "./Video.css";

const Video = () => {
    return (
        <div className='video-color'>
            <div className="col-md-10">
                <div className="block glow">
                    <div className="video-wrapper">
                        <iframe 
                            src="https://player.vimeo.com/video/975112120?h=31e80a3fe1" 
                            frameborder="0" 
                            allow="autoplay; fullscreen; picture-in-picture" 
                            allowfullscreen>
                        </iframe>
                    </div>
                </div>
            </div>
           <br></br>
        </div>
    );
}

export default Video;
