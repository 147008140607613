import React from "react";
import "./Roadmap.css";

const Roadmap = () => {
    return (
        <div>
            <div className="responsive-center">
            </div>
           
        </div>
    );
}

export default Roadmap;
