// src/pages/Faq.js

import React, { useState } from 'react';
import './Faq.css'; // Import your CSS file if you have styles for FAQ

const Faq = () => {
    // State to manage visibility of answers
    const [isOpen, setIsOpen] = useState(false);

    // Function to toggle answer visibility
    const toggleAnswer = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <div>
                <div>
                    <div>
                    <div className="responsive-center">
        
            <div className="faq-containers">
                <div className="faq-item">
                    <div className="faq-question" onClick={toggleAnswer}>
                    How many NFTs are available?
                        <img src="/images/Arrow.png" alt="Toggle arrow" className={`toggle-arrow ${isOpen ? 'open' : ''}`} />
                    </div>
                    <div className={`faq-answer ${isOpen ? 'open' : ''}`}>
                    A total of 2,007 NFTs are available.
                    </div>
                </div>
                <div className="faq-item">
                    <div className="faq-question" onClick={toggleAnswer}>
                       
                    What is the price?
                        <img src="/images/Arrow.png"  alt="Toggle arrow" className={`toggle-arrow ${isOpen ? 'open' : ''}`} />
                    </div>
                    <div className={`faq-answer ${isOpen ? 'open' : ''}`}>
                    The price will be revealed on social channels.
                    </div>
                </div>
                <div className="faq-item">
                    <div className="faq-question" onClick={toggleAnswer}>
                    Where can I mint?
                        <img src="/images/Arrow.png"  alt="Toggle arrow" className={`toggle-arrow ${isOpen ? 'open' : ''}`} />
                    </div>
                    <div className={`faq-answer ${isOpen ? 'open' : ''}`}>
                        
                      You can mint at <span>merrymon.com/mint.</span>
                    </div>
                </div>
            </div>
        </div>

                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default Faq;